import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from "react-router-dom";
import ProtectedRoute from 'shared/components/ProtectedRoute';
import Header from 'containers/Header/Header';
import Footer from 'containers/Footer/Footer';
import './App.scss';
import "./style/_normalize.scss";
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfileAttempt } from 'store/account/profile/profileActions';
import { selectIsAuthenticated, isBrowser, Languages } from 'common';
import { routes } from 'routes';
import { IntlProvider } from 'react-intl';
import { fetchTranslationAttempt } from 'store/translations/translationsActions';
import { selectTranslations } from 'store/translations/translationsSelectors';
import { selectBanner } from 'store/banner/bannerSelectors';
const BY_PASS_KEY = '2PulseByPass';
function App() {
    var _a, _b, _c, _d;
    const dispatch = useDispatch();
    // ReactGA.initialize('UA-69522867-1');
    const isAuthenticated = useSelector(selectIsAuthenticated());
    const { pathname, search } = useLocation();
    const byPass = new URLSearchParams(search).get(BY_PASS_KEY);
    const IS_B2C = (((_b = (_a = window) === null || _a === void 0 ? void 0 : _a.__env__) === null || _b === void 0 ? void 0 : _b.APP_MODE) == 'B2C') ? true : false;
    console.log('APP_MODE', (((_d = (_c = window) === null || _c === void 0 ? void 0 : _c.__env__) === null || _d === void 0 ? void 0 : _d.APP_MODE) == 'B2C'));
    console.log('IS_B2C', IS_B2C);
    if (isBrowser() && byPass) {
        localStorage.setItem(BY_PASS_KEY, 'true');
    }
    // ReactGA.pageview(pathname);
    const getRootClasses = () => {
        return pathname === '/' ? 'root-black-background' : '';
    };
    useEffect(() => {
        if (isAuthenticated)
            dispatch(fetchProfileAttempt());
    }, [isAuthenticated]);
    const getLanguageFromUrl = (search) => {
        const params = new URLSearchParams(search);
        return params.get("lang"); // Récupère la langue si elle est dans l'URL
    };
    const browserLanguage = 'fr'; //navigator?.language?.split(/[-_]/)[0];
    const language = getLanguageFromUrl(search) || browserLanguage;
    useEffect(() => {
        if (language !== Languages.FRENCH) {
            dispatch(fetchTranslationAttempt(language));
        }
    }, [language]);
    const messages = useSelector(selectTranslations);
    const banner = useSelector(selectBanner);
    let showBanner = false;
    if (IS_B2C && new Date() >= new Date(banner.B2CdateFrom) && new Date() <= new Date(banner.B2CdateTo) && !banner.B2Cdisabled) {
        showBanner = true;
    }
    if (!IS_B2C && new Date() >= new Date(banner.dateFrom) && new Date() <= new Date(banner.dateTo) && !banner.disabled) {
        showBanner = true;
    }
    // let isMaintenance = false;
    // isMaintenance = isBrowser()
    //   ? (`${(window as any).__env__?.RAZZLE_ENABLE_MAINTENANCE}` && !localStorage.getItem(BY_PASS_KEY))
    //   : (`${process.env.RAZZLE_ENABLE_MAINTENANCE}` && !byPass);
    // console.log({
    //   isMaintenance,
    //   local: isBrowser() ? localStorage.getItem(BY_PASS_KEY) : null,
    //   envVar: isBrowser()
    //     ? (`${(window as any).__env__?.RAZZLE_ENABLE_MAINTENANCE}`)
    //     : (`${process.env.RAZZLE_ENABLE_MAINTENANCE}`),
    //   byPass,
    // })
    // if (isMaintenance) {
    //   return (
    //     <Maintenance />
    //   )
    // }
    return (React.createElement(IntlProvider, { locale: language, messages: messages, defaultLocale: "fr" },
        React.createElement("div", { className: `${getRootClasses()} ${showBanner && 'banner-active'} k-container` },
            React.createElement(Header, null),
            React.createElement(Switch, null, routes.map((route, index) => {
                return !!route.protected
                    ? React.createElement(ProtectedRoute, { key: index, path: route.path },
                        React.createElement(Route, { component: route.component }))
                    : React.createElement(Route, { key: index, exact: route.exact || false, path: route.path, component: route.component });
            })),
            React.createElement(Footer, null))));
}
export default App;
