import React from "react";
import { connect } from 'react-redux';
import Initialize from './Initialize';
import Recover from "./Recover";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
function Recovery({ query }) {
    return (React.createElement("div", { className: "k-auth" },
        React.createElement(Helmet, null,
            React.createElement("title", null, "R\u00E9cup\u00E9ration de mot de passe - KUST | La Marque des Coiffeurs pas Comme Les Autres")),
        React.createElement("div", { className: "k-auth--container" },
            React.createElement("header", { className: "k-auth--header" },
                React.createElement("h1", null,
                    React.createElement(FormattedMessage, { id: "Mot de passe oubli\u00E9", defaultMessage: "Mot de passe oubli\u00E9" })),
                React.createElement("p", null,
                    React.createElement(FormattedMessage, { id: "R\u00E9initialisez votre mot de passe pour acc\u00E9der \u00E0 votre compte.", defaultMessage: "R\u00E9initialisez votre mot de passe pour acc\u00E9der \u00E0 votre compte." }))),
            React.createElement("div", { className: "k-auth--content" }, query.token ? React.createElement(Recover, null) : React.createElement(Initialize, null)))));
}
const mapStateToProps = (state) => ({
    query: state.router.location.query
});
export default connect(mapStateToProps)(Recovery);
