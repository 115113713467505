import React, { useEffect } from "react";
import useStep from "shared/hooks/useStep";
import { AddressesSteps } from "./AddressesSteps";
import { PaymentStep } from "./PaymentStep";
import { ShippingsStep } from "./ShippingsStep";
import "./OrderSteps.scss";
import { Helmet } from "react-helmet";
export var Steps;
(function (Steps) {
    Steps[Steps["BILLING_ADDRESS_STEP"] = 1] = "BILLING_ADDRESS_STEP";
    Steps[Steps["SHIPPING_ADDRESS_STEP"] = 2] = "SHIPPING_ADDRESS_STEP";
    Steps[Steps["SHIPPING_MODE_STEP"] = 3] = "SHIPPING_MODE_STEP";
    Steps[Steps["PAYMENT_STEP"] = 4] = "PAYMENT_STEP";
})(Steps || (Steps = {}));
export function OrderSteps({ order, setOrderValue }) {
    const [activeOrderStep, setActiveOrderStep] = useStep(Steps.BILLING_ADDRESS_STEP);
    useEffect(() => {
        if (activeOrderStep === Steps.PAYMENT_STEP) {
            // Charger les scripts et styles Lyra lors de l'étape de paiement
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = 'https://api.lyra.com/static/js/krypton-client/V4.0/ext/classic-reset.css';
            document.head.appendChild(link);
            const script = document.createElement('script');
            script.src = 'https://api.lyra.com/static/js/krypton-client/V4.0/ext/classic.js';
            script.async = true;
            document.body.appendChild(script);
            return () => {
                // Nettoyage des scripts si nécessaire
                document.head.removeChild(link);
                document.body.removeChild(script);
            };
        }
    }, [activeOrderStep]); // Ce useEffect ne s'exécute que lorsque activeOrderStep change
    return (React.createElement("div", { className: "k-cart--steps" },
        React.createElement(Helmet, null,
            React.createElement("title", null, "Commander - KUST | La Marque des Coiffeurs pas Comme Les Autres")),
        React.createElement(AddressesSteps, { orderValues: order, setOrderValue: setOrderValue, activeOrderStep: activeOrderStep, setActiveOrderStep: setActiveOrderStep }),
        order.billingAddress != null && order.shippingAddress != null &&
            React.createElement(React.Fragment, null,
                React.createElement(ShippingsStep, { orderValues: order, setOrderValue: setOrderValue, activeOrderStep: activeOrderStep, setActiveOrderStep: setActiveOrderStep }),
                React.createElement(PaymentStep, { orderValues: order, setOrderValue: setOrderValue, activeOrderStep: activeOrderStep, setActiveOrderStep: setActiveOrderStep }))));
}
