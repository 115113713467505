import { selectErrors, selectPending } from "common";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStep from "shared/hooks/useStep";
import { createAddressAttempt } from "store/account/addresses/addressActions";
import { selectAddresses } from "store/account/profile/profileSelectors";
import { selectCart } from "store/cart/cartSelectors";
import { fetchShippingFeesAttempt } from "store/shippings/shippingsActions";
import { AddressStep } from "./components/AddressStep";
import { CreateAddress } from "./components/CreateAddress";
import { StepButton } from "./components/StepButton";
import { Steps } from "./OrderSteps";
import { FormattedMessage, useIntl } from "react-intl";
var AddressTypes;
(function (AddressTypes) {
    AddressTypes["BILLING"] = "billingAddress";
    AddressTypes["SHIPPING"] = "shippingAddress";
})(AddressTypes || (AddressTypes = {}));
export function AddressesSteps({ orderValues, setOrderValue, activeOrderStep, setActiveOrderStep }) {
    const dispatch = useDispatch();
    const intl = useIntl();
    const addresses = useSelector(selectAddresses);
    const cart = useSelector(selectCart);
    const [useSameAddress, setUseSameAddress] = useState(false);
    const [activeBillingStep, setActiveBillingStep] = useStep();
    const [activeShippingStep, setActiveShippingStep] = useStep();
    const createAddressErrors = useSelector(selectErrors('CREATE_ADDRESS'));
    const createAddressPending = useSelector(selectPending('CREATE_ADDRESS'));
    const fetchShippingFeesPending = useSelector(selectPending('FETCH_SHIPPING_FEES'));
    useEffect(() => {
        if (!!orderValues.shippingAddress) {
            const shippingAddress = orderValues.shippingAddress;
            fetchShippingFees(cart.total, cart.totalVat, shippingAddress);
        }
    }, [dispatch, orderValues.shippingAddress]);
    useEffect(() => {
        if (!!orderValues.billingAddress && useSameAddress) {
            const billingAddress = orderValues.billingAddress;
            fetchShippingFees(cart.total, cart.totalVat, billingAddress);
        }
    }, [dispatch, orderValues.billingAddress, useSameAddress]);
    const fetchShippingFees = (total, totalVat, address) => {
        dispatch(fetchShippingFeesAttempt({
            total,
            totalVat,
            postalCode: address.postalCode,
            country: address.country
        }));
    };
    const selectBillingAddress = (address) => {
        const addressId = (address === null || address === void 0 ? void 0 : address._id) || '';
        if (activeBillingStep !== addressId)
            setActiveBillingStep(addressId);
        setOrderValue(AddressTypes.BILLING, address);
    };
    const selectShippingAddress = (address) => {
        const addressId = (address === null || address === void 0 ? void 0 : address._id) || '';
        if (activeShippingStep !== addressId)
            setActiveShippingStep(addressId);
        setOrderValue(AddressTypes.SHIPPING, address);
    };
    const callCreateAddress = (address, addressType) => {
        const setActiveStep = addressType === AddressTypes.BILLING
            ? setActiveBillingStep
            : setActiveShippingStep;
        dispatch(createAddressAttempt(address, (err, res) => {
            if (res) {
                setActiveStep(res._id);
                setOrderValue(addressType, res);
            }
        }));
    };
    return (React.createElement("div", null,
        React.createElement(AddressStep, { name: AddressTypes.BILLING, title: intl.formatMessage({ id: "Informations de facturation", defaultMessage: "Informations de facturation" }), description: intl.formatMessage({ id: "Sélectionnez une de vos adresses ou enregistrez-en une nouvelle pour la facturation.", defaultMessage: "Sélectionnez une de vos adresses ou enregistrez-en une nouvelle pour la facturation." }), disableCurrentStep: false, step: Steps.BILLING_ADDRESS_STEP, addresses: addresses, selectAddress: selectBillingAddress, activeOrderStep: activeOrderStep, activeStep: activeBillingStep, setActiveOrderStep: setActiveOrderStep },
            React.createElement(CreateAddress, { name: AddressTypes.BILLING, activeStep: activeBillingStep, createAddress: (address) => callCreateAddress(address, AddressTypes.BILLING), selectAddress: selectBillingAddress, pending: createAddressPending, errors: createAddressErrors }),
            React.createElement("div", null,
                React.createElement("label", { className: "is-checkbox radio radio-before", htmlFor: "sameAddress" },
                    React.createElement("span", { className: "radio__input" },
                        React.createElement("input", { type: "checkbox", className: "checkbox", checked: useSameAddress, id: "sameAddress", onChange: () => setUseSameAddress(!useSameAddress) }),
                        React.createElement("span", { className: "radio__control" })),
                    React.createElement("strong", null,
                        React.createElement(FormattedMessage, { id: "Livrer \u00E0 cette adresse", defaultMessage: "Livrer \u00E0 cette adresse" }))),
                activeOrderStep === Steps.BILLING_ADDRESS_STEP &&
                    React.createElement(StepButton, { loading: fetchShippingFeesPending, disabled: !orderValues.billingAddress, onClick: () => {
                            if (useSameAddress) {
                                selectShippingAddress(orderValues.billingAddress);
                                setActiveOrderStep(Steps.SHIPPING_MODE_STEP);
                            }
                            else {
                                setActiveOrderStep(Steps.SHIPPING_ADDRESS_STEP);
                            }
                        } }))),
        orderValues.billingAddress != null &&
            React.createElement(AddressStep, { name: AddressTypes.SHIPPING, title: intl.formatMessage({ id: "Informations de livraison", defaultMessage: "Informations de livraison" }), description: intl.formatMessage({ id: "Adresse existante ou nouvelle pour l’envoi de vos produits.", defaultMessage: "Adresse existante ou nouvelle pour l’envoi de vos produits." }), disableCurrentStep: !orderValues.billingAddress, step: Steps.SHIPPING_ADDRESS_STEP, addresses: addresses, selectAddress: selectShippingAddress, activeOrderStep: activeOrderStep, activeStep: activeShippingStep, setActiveOrderStep: setActiveOrderStep },
                React.createElement(CreateAddress, { name: AddressTypes.SHIPPING, activeStep: activeShippingStep, createAddress: (address) => callCreateAddress(address, AddressTypes.SHIPPING), selectAddress: selectShippingAddress, pending: createAddressPending, errors: createAddressErrors }),
                activeOrderStep === Steps.SHIPPING_ADDRESS_STEP &&
                    React.createElement(StepButton, { loading: fetchShippingFeesPending, disabled: !orderValues.shippingAddress, onClick: () => setActiveOrderStep(Steps.SHIPPING_MODE_STEP) }))));
}
