import { elementPriceCartProduct } from 'services/utils/product.mapper';
import { CartActionTypes, } from "./cartTypes";
export const addToCart = (product) => ({
    type: CartActionTypes.ADD_TO_CART,
    payload: elementPriceCartProduct(product)
});
export const editToCart = (product) => ({
    type: CartActionTypes.EDIT_TO_CART,
    payload: elementPriceCartProduct(product)
});
export const removeFromCart = (product) => ({
    type: CartActionTypes.REMOVE_FROM_CART,
    payload: product
});
export const decrementProductQuantityCart = (product) => ({
    type: CartActionTypes.DECREMENT_PRODUCT_QUANTITY,
    payload: product
});
export const incrementProductQuantityCart = (product) => ({
    type: CartActionTypes.INCREMENT_PRODUCT_QUANTITY,
    payload: product
});
export const toggleAddToCartAnimationAction = (toggle) => ({
    type: CartActionTypes.TOGGLE_ADD_TO_CART_ANIMATION,
    payload: toggle
});
export const prevalidateCartAttempt = (cart, businessType) => ({
    type: CartActionTypes.PREVALIDATE_CART_ATTEMPT,
    payload: { cart, businessType }
});
export const prevalidateCartSuccess = (cart) => ({
    type: CartActionTypes.PREVALIDATE_CART_SUCCESS,
    payload: cart
});
export const prevalidateCartFailure = (error) => ({
    type: CartActionTypes.PREVALIDATE_CART_FAILURE,
    payload: error
});
export const verifyCartPromotionAttempt = (code, cart) => ({
    type: CartActionTypes.VERIFY_CART_PROMOTION_ATTEMPT,
    payload: { code, cart }
});
export const verifyCartPromotionSuccess = (payload) => ({
    type: CartActionTypes.VERIFY_CART_PROMOTION_SUCCESS,
    payload
});
export const verifyCartPromotionFailure = (error) => ({
    type: CartActionTypes.VERIFY_CART_PROMOTION_FAILURE,
    payload: error
});
export const fetchUserPromotionAttempt = (cart) => ({
    type: CartActionTypes.FETCH_USER_PROMOTION_ATTEMPT,
    payload: cart
});
export const fetchUserPromotionSuccess = (payload) => ({
    type: CartActionTypes.FETCH_USER_PROMOTION_SUCCESS,
    payload
});
export const fetchUserPromotionFailure = (error) => ({
    type: CartActionTypes.FETCH_USER_PROMOTION_FAILURE,
    payload: error
});
export const setFeesToCart = (fees) => ({
    type: CartActionTypes.SET_FEES_TO_CART,
    payload: fees
});
export const resetCart = () => ({
    type: CartActionTypes.RESET_CART,
});
export const resetCartFees = () => ({
    type: CartActionTypes.RESET_CART_FEES,
});
export const removePromotionFromCart = () => ({
    type: CartActionTypes.REMOVE_PROMOTION_FROM_CART,
});
export const togglePromotionOpen = (payload) => ({
    type: CartActionTypes.TOGGLE_PROMOTION_OPEN,
    payload,
});
export const selectProductToModify = (key) => ({
    type: CartActionTypes.SELECT_PRODUCT_TO_MODIFY,
    payload: key
});
export const prevalidateCartToQuotationAttempt = (cart, businessType) => ({
    type: CartActionTypes.PREVALIDATE_CART_TO_QUOTATION_ATTEMPT,
    payload: { cart, businessType }
});
export const prevalidateCartToQuotationSuccess = (cart) => ({
    type: CartActionTypes.PREVALIDATE_CART_TO_QUOTATION_SUCCESS,
    payload: cart
});
export const prevalidateCartToQuotationFailure = (error) => ({
    type: CartActionTypes.PREVALIDATE_CART_TO_QUOTATION_FAILURE,
    payload: error
});
