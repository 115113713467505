import { CartActionTypes } from "./cartTypes";
import { getItem, findSameProductIndex, editProductCart } from 'common';
const cartLocalState = JSON.parse(getItem('cart') || '{}');
const initialState = {
    fees: cartLocalState === null || cartLocalState === void 0 ? void 0 : cartLocalState.fees,
    products: (cartLocalState === null || cartLocalState === void 0 ? void 0 : cartLocalState.products) || [],
    productAddedToCart: false,
    promotion: cartLocalState === null || cartLocalState === void 0 ? void 0 : cartLocalState.promotion,
    isPromoOpen: false,
    edit: cartLocalState === null || cartLocalState === void 0 ? void 0 : cartLocalState.edit,
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CartActionTypes.ADD_TO_CART:
            const index = findSameProductIndex(state.products, action.payload);
            return Object.assign(Object.assign({}, state), { products: index !== -1
                    ? state.products
                        .map((product, i) => {
                        let quantity = product.quantity;
                        quantity += index === i ? action.payload.quantity : 0;
                        return Object.assign(Object.assign({}, product), { quantity });
                    })
                    : [...state.products, action.payload] });
        case CartActionTypes.EDIT_TO_CART:
            const indexProd = findSameProductIndex(state.products, action.payload);
            return Object.assign(Object.assign({}, state), { products: editProductCart(state.products, state.edit, action.payload, indexProd), edit: null });
        case CartActionTypes.INCREMENT_PRODUCT_QUANTITY:
            return Object.assign(Object.assign({}, state), { products: state.products
                    .map((product, i) => {
                    let quantity = product.quantity;
                    quantity += findSameProductIndex(state.products, action.payload) === i
                        ? 1 : 0;
                    return Object.assign(Object.assign({}, product), { quantity });
                })
                    .filter(product => product.quantity > 0) });
        case CartActionTypes.DECREMENT_PRODUCT_QUANTITY:
            return Object.assign(Object.assign({}, state), { products: state.products
                    .map((product, i) => {
                    let quantity = product.quantity;
                    quantity -= findSameProductIndex(state.products, action.payload) === i
                        ? 1 : 0;
                    return Object.assign(Object.assign({}, product), { quantity });
                })
                    .filter(product => product.quantity > 0) });
        case CartActionTypes.REMOVE_FROM_CART:
            return Object.assign(Object.assign({}, state), { promotion: null, products: state.products
                    .map((product, i) => {
                    return Object.assign(Object.assign({}, product), { quantity: findSameProductIndex(state.products, action.payload) === i
                            ? 0 : product.quantity });
                })
                    .filter(product => product.quantity > 0) });
        case CartActionTypes.SET_FEES_TO_CART:
            return Object.assign(Object.assign({}, state), { fees: action.payload });
        case CartActionTypes.TOGGLE_ADD_TO_CART_ANIMATION:
            return Object.assign(Object.assign({}, state), { productAddedToCart: action.payload });
        case CartActionTypes.TOGGLE_PROMOTION_OPEN:
            return Object.assign(Object.assign({}, state), { isPromoOpen: action.payload || !state.isPromoOpen });
        case CartActionTypes.REMOVE_PROMOTION_FROM_CART:
            return Object.assign(Object.assign({}, state), { promotion: null });
        case CartActionTypes.FETCH_USER_PROMOTION_SUCCESS:
        case CartActionTypes.VERIFY_CART_PROMOTION_SUCCESS:
            const { promotion } = action.payload;
            return Object.assign(Object.assign({}, state), { promotion });
        case CartActionTypes.RESET_CART:
            return {
                fees: null,
                total: 0,
                totalVat: 0,
                totalWeight: 0,
                totalWithPromo: 0,
                totalVatWithPromo: 0,
                products: [],
                productAddedToCart: false,
                promotion: null,
                promoReduction: 0,
                isPromoOpen: false,
                edit: null,
            };
        case CartActionTypes.RESET_CART_FEES:
            return Object.assign(Object.assign({}, state), { fees: null });
        case CartActionTypes.SELECT_PRODUCT_TO_MODIFY:
            return Object.assign(Object.assign({}, state), { edit: action.payload });
        default:
            return state;
    }
};
export { reducer as cartReducer };
